<template>
  <div class="container">
    <van-nav-bar
      :title="newsDetail.title"
      left-arrow
      fixed
      placeholder
      @click-left="onClickLeft"
    />
    <div class="content" v-html="newsDetail.newsContent"></div>
  </div>
</template>

<script>
import baseURL from "../../utils/config";
import axios from 'axios';
export default {
  name: "NewsDetail",
  data() {
    return {
      newsDetail: "",
      // imgUrl: baseURL.imageUrl,
      imgUrl: 'https://www.jtgj.cc',
    };
  },
  mounted() {
    this.getNewsDetail();
  },
  methods: {
    getNewsDetail() {
      this.$api.getNewsDetail(this.$route.params.newsID).then((res) => {
        this.newsDetail = res;
      });
      // axios.defaults.baseURL = 'https://www.jtgj.cc/ncapi/' + this.$route.params.newsID
      // axios.get().then(response =>{
      //   this.newsDetail = response.data
      // }).catch(error =>{})
    },
    onClickLeft() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
}
.van-nav-bar .van-icon {
  color: black;
}
::v-deep .van-nav-bar__title {
  font-size: 16px;
  font-weight: bold;
  margin: 0 auto;
  // color: #323233;
  max-width: 70% !important;
  padding-top: 10px;
  max-height: 60px;
  overflow: hidden;
  white-space: break-spaces;
  text-emphasis: ellipsis;
}
.topImg {
  width: 100%;
}
::v-deep .content {
  margin: 1rem;
  margin-top: 2rem;
}
::v-deep .van-nav-bar__content {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  height: 60px;
}
</style>